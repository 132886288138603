<script setup lang="ts">
import { UIIcon } from '@groover-dev/groover-ui'

import type { IconColor } from '~/types/color'

type Props = {
  color?: IconColor
  size?: InstanceType<typeof UIIcon>['size']
  sizeOverride?: `${number}px`
}

const props = withDefaults(defineProps<Props>(), {
  color: 'icon-brand',
  size: 'base',
  sizeOverride: undefined,
})

const style = computed(() => {
  if (props.sizeOverride) {
    return {
      width: `${props.sizeOverride}`,
      height: `${props.sizeOverride}`,
    }
  }
  return {}
})

const sizeAttrs = computed(() =>
  props.sizeOverride
    ? {
        height: props.sizeOverride,
        width: props.sizeOverride,
      }
    : {},
)

const cssColorClass = computed(() => `tw-text-${props.color}`)
</script>

<script lang="ts">
/**
 * Displays the Groover logo icon.
 */
export default {}
</script>

<template>
  <UIIcon
    name="groover:logo"
    :class="cssColorClass"
    :size
    :style
    v-bind="sizeAttrs"
  />
</template>
